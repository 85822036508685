import * as React from 'react';
import { graphql } from "gatsby";
import Layout from '../components/layout';
import { Helmet } from 'react-helmet';

const Template = ({data}: any): JSX.Element => {
  const {markdownRemark} = data;
  const {frontmatter, html} = markdownRemark;
  const {locale} = frontmatter;
  const classLocale = (locale === 'fa' || locale === 'ps') ? 'text-rtl' : 'text-ltr';

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{frontmatter.title}</title>
      </Helmet>
      <div className={"ui basic segment blog-post-container " + classLocale}>
        <h1 className="ui header">
          {frontmatter.title}
          <div className="sub header">{frontmatter.date}</div>
        </h1>
        <p className="blog-post-content" dangerouslySetInnerHTML={{__html: html}}/>
      </div>
    </Layout>
  );
};

export default Template;

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        locale
      }
    }
  }
`;